import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gopremium-modal',
  templateUrl: './gopremium-modal.page.html',
  styleUrls: ['./gopremium-modal.page.scss'],
})
export class GopremiumModalPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
