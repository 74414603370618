/**
 * Shoppr - E-commerce app starter Ionic 5(https://www.enappd.com)
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source .
 *
 */
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Order } from '../../models/order';
import { FunctionsService } from '../../services-shop/functions.service';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.page.html',
  styleUrls: ['./order-info.page.scss']
})
export class OrderInfoPage implements OnInit {
  order: any;

  constructor(
    private modalController: ModalController,
    private params: NavParams,
    public fun: FunctionsService
  ) {
    this.order = params.get('value');
  }

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss();
  }
}
